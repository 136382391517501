import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/styles/makeStyles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../../Modal";
import Dropzone from "./Dropzone";
import * as style from "./style.module.scss";

interface Props {
    show: boolean;
    onClose: () => void;
    onUpload: (files: File[]) => void;
    allowedExtensions?: string[];
}

const useStyles = makeStyles(() => ({
    modal: {
        margin: "3em 0",
        overflow: "scroll",
    },
}));

const UploadModal = ({
    show,
    onClose,
    onUpload,
    allowedExtensions,
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    const classes = useStyles();

    return (
        <Modal
            openDialog={show}
            closeDialog={onClose}
            className={classes.modal}
        >
            <Grid
                container
                direction="column"
                className={style.uploadModalContainer}
            >
                <Grid item>
                    <Dropzone
                        types={allowedExtensions}
                        files={[]}
                        onAddFile={newFiles => setFiles(newFiles)}
                    />
                </Grid>
                <Grid item>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={onClose}
                            >
                                {t("Cancel")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                color="primary"
                                disabled={!files.length}
                                onClick={() => onUpload(files)}
                            >
                                {t("Upload files")}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default UploadModal;
