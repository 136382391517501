import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";

import Modal from "../../Modal";
import * as style from "./style.module.scss";

interface Props {
    show: boolean;
    error: boolean;
    onClose: () => void;
}

const UploadingModal = ({ show, error, onClose }: Props): JSX.Element => {
    return (
        <Modal openDialog={show} closeDialog={() => undefined}>
            <Grid
                container
                direction="column"
                className={style.uploadModalContainer}
            >
                <Grid item>
                    {!error && (
                        <Grid container justifyContent="space-between">
                            <Typography>Upload in progress</Typography>
                        </Grid>
                    )}
                    {error && (
                        <Grid container justifyContent="space-between">
                            <Typography>
                                Something went wrong, please try again
                            </Typography>
                            <Typography>
                                <Button onClick={onClose}>Close</Button>
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default UploadingModal;
