interface ReturnValue {
    start: () => void;
    stop: () => void;
    setInterval: (interval: number) => void;
    setCallback: (callback: () => unknown) => void;
}

const DEFAULT_INTERVAL = 60000;

/**
 * A polling utility
 */
export default (
    callback: () => unknown,
    interval: number = DEFAULT_INTERVAL,
): ReturnValue => {
    let currentCallback: () => unknown = callback;
    let currentInterval: number = interval;
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const loop = async () => {
        await Promise.resolve(currentCallback());
        timeoutId = setTimeout(loop, currentInterval);
    };

    const start = () => {
        // Already started, returning
        if (timeoutId) {
            return;
        }

        loop();
    };

    const stop = () => {
        // If not running, return
        if (!timeoutId) {
            return;
        }

        clearTimeout(timeoutId);
        timeoutId = null;
    };

    const setInterval = (interval: number) => {
        currentInterval = interval;

        // Re-enable new interval if set
        if (timeoutId) {
            stop();
            loop();
        }
    };

    const setCallback = (callback: () => unknown) => {
        currentCallback = callback;
    };

    return {
        setCallback,
        setInterval,
        start,
        stop,
    };
};
