import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import React, { Fragment, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import packageJson from "../../../package.json";
import AppLayout from "../../components/AppLayout/index";
import ErrorDialog from "../../components/ErrorDialog";
import Loader from "../../components/Loader";
import UploadingModal from "../../components/RecordingList/UploadingModal";
import UploadModal from "../../components/RecordingList/UploadModal";
import EnhancedTable from "../../components/Table/index";
import EnhancedTableRow from "../../components/Table/TableRow/index";
import { useReportsService } from "../../services/reports";
import { getClient } from "../../utils/api";
import * as style from "./style.module.scss";

/**
 * Test if a record is ready for download
 */
const isReadyForDownload = (report: {
    status: string;
    reportFiles: { locations: string[] };
}) => {
    if (
        report.reportFiles.locations.includes("report.pdf") ||
        report.reportFiles.locations.includes("report.edf") ||
        report.reportFiles.locations.includes("annotations.zip")
    ) {
        return report.status === "done";
    }

    return false;
};

const RecordingList = (): JSX.Element => {
    const { t } = useTranslation();
    const [search, setSearch] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const { reports, openReport, loading, error, resetError } =
        useReportsService(search);

    const headCells = [
        {
            disablePadding: false,
            id: "fullName",
            label: t("Name"),
            numeric: false,
            sortable: true,
        },
        {
            disablePadding: false,
            id: "birthDay",
            label: t("Birth Date"),
            numeric: false,
            sortable: true,
        },
        {
            disablePadding: false,
            id: "creationTimestamp",
            label: t("Creation Date"),
            numeric: false,
            sortable: true,
        },
        {
            disablePadding: false,
            id: "status",
            label: t("Current State"),
            numeric: false,
            sortable: false,
        },
    ];

    const STATUS_MAP: Record<string, (key: string) => string> = {
        accepted: t("Accepted"),
        done: t("Downloading"),
        downloaded: t("Done"), // Redundant
        "non-compliant": t("Non-compliant"),
        processing: t("Processing"),
        "recording incomplete": t("Recording incomplete"),
        rejected: t("Non-compliant"),
        submitted: t("Processing"),
        waiting: t("Processing"),
    };

    const addedFile = async (files: File[]) => {
        setOpenDialog(false);
        setUploading(true);

        try {
            for (const file of files) {
                // Get credentials
                const credentials: AxiosResponse<{
                    accessKey: string;
                    secretKey: string;
                    sessionToken: string;
                    key: string;
                    bucket: string;
                }> = await getClient().post("/account/uploadcredentials", {
                    fileName: `browser-upload/${Date.now()}-${file.name}`,
                });

                // Start the upload
                const upload = new Upload({
                    client: new S3Client({
                        credentials: {
                            accessKeyId: credentials.data.accessKey,
                            secretAccessKey: credentials.data.secretKey,
                            sessionToken: credentials.data.sessionToken,
                        },
                        region: "eu-central-1",
                    }),
                    params: {
                        Body: file,
                        Bucket: credentials.data.bucket,
                        Key: credentials.data.key,
                        Metadata: {
                            // TODO: When applied on API, can safely switch to SHA1 which is
                            // cryptographically more secure for brute force back calculation
                            // This would be unable to do on the frontend but is not intended
                            // behaviour anyway. So leave this for now.
                            originalFileName: file.name,
                            timestamp: `${Date.now()}`,
                            useragent: navigator.userAgent,
                            version: packageJson.version,
                        },
                    },
                });

                await upload.done();
            }

            // All done
            setUploading(false);
        } catch {
            // Message error
            setUploadError(true);
            setUploading(false);
        }
    };

    const status = (status: string) => {
        return (
            <Fragment>
                {STATUS_MAP[status] || STATUS_MAP["recording incomplete"]}
            </Fragment>
        );
    };

    const parseDate = (date: string | null) => {
        if (!date) {
            return t("Not yet available");
        }

        if (DateTime.fromISO(date).toFormat("d/M/y") === "Invalid DateTime") {
            return date;
        }

        return DateTime.fromISO(date).toFormat("d/M/y");
    };

    return (
        <AppLayout currentPage={t("Recording list")}>
            <Grid container spacing={2} direction="column">
                <Grid item>
                    <TextField
                        id="search"
                        value={search}
                        variant="outlined"
                        label={t("Search by Patient Name")}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => setSearch(event.target.value)}
                    />
                </Grid>
                <Grid item>
                    <Loader loading={loading}>
                        <EnhancedTable headCells={headCells}>
                            {reports?.map(report => {
                                return (
                                    <EnhancedTableRow
                                        onContextMenu={(
                                            e: MouseEvent<HTMLButtonElement>,
                                        ) => {
                                            e.preventDefault();
                                            alert(`Report ID: ${report.id}`);
                                        }}
                                        key={report.id}
                                    >
                                        <TableCell align="left">
                                            {report.fullName
                                                ? report.fullName
                                                : t("New report", {
                                                      id: report.id
                                                          .toString()
                                                          .substring(0, 6),
                                                  })}
                                        </TableCell>
                                        <TableCell align="left">
                                            {parseDate(report.birthDay)}
                                        </TableCell>
                                        <TableCell align="left">
                                            {parseDate(
                                                report.creationTimestamp,
                                            )}
                                        </TableCell>
                                        <TableCell align="left">
                                            {(isReadyForDownload(report) && (
                                                <Grid item>
                                                    <Grid container spacing={2}>
                                                        {report.reportFiles.locations.includes(
                                                            "report.pdf",
                                                        ) && (
                                                            <Grid item>
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        openReport(
                                                                            report.id,
                                                                            "report.pdf",
                                                                        );
                                                                    }}
                                                                >
                                                                    {t(
                                                                        "Open Report",
                                                                    )}
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                        {report.reportFiles.locations.includes(
                                                            "report.edf",
                                                        ) && (
                                                            <Grid item>
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        openReport(
                                                                            report.id,
                                                                            "report.edf",
                                                                        );
                                                                    }}
                                                                >
                                                                    {t(
                                                                        "Open Results",
                                                                    )}
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                        {report.reportFiles.locations.includes(
                                                            "annotations.zip",
                                                        ) && (
                                                            <Grid item>
                                                                <Button
                                                                    color="primary"
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        openReport(
                                                                            report.id,
                                                                            "annotations.zip",
                                                                        );
                                                                    }}
                                                                >
                                                                    {t(
                                                                        "Open Results",
                                                                    )}
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            )) ||
                                                status(report.status)}
                                        </TableCell>
                                    </EnhancedTableRow>
                                );
                            }) || []}
                        </EnhancedTable>
                    </Loader>
                </Grid>
            </Grid>
            <Fab
                color="secondary"
                className={style.addIcon}
                aria-label="add"
                onClick={() => setOpenDialog(true)}
            >
                <AddIcon></AddIcon>
            </Fab>
            <UploadModal
                onClose={() => setOpenDialog(false)}
                show={openDialog}
                onUpload={addedFile}
            />
            <UploadingModal
                show={uploadError || uploading}
                error={uploadError}
                onClose={() => {
                    setUploadError(false);
                }}
            />
            <ErrorDialog error={error} reset={resetError} />
        </AppLayout>
    );
};

export default RecordingList;
