/**
 * A service that scans for recordings and updates the state
 */

import { AxiosResponse } from "axios";
import get from "lodash.get";
import { DateTime } from "luxon";
import { useEffect } from "react";
import { atom, useRecoilValue } from "recoil";
import { getRecoil, setRecoil } from "recoil-nexus";

import { getClient } from "../../utils/api";
import Poller from "../../utils/poller";

interface ReportData {
    id: string;
    status: string;
    creationTimestamp: string;
    userNote: string;
    adminNote: string;
    birthDay: string;
    fullName: string;
    reportFiles: {
        locations: string[];
    };
}

/*
 *  @deprecated
 */
interface OldReportsResponse {
    patients: ReportData[];
}

interface ReportsResponse {
    recordings: ReportData[];
}

type Report = ReportData;

interface State {
    loading: boolean;
    reports: Report[] | null;
    error: Error | null;
}

interface Hook extends State {
    openReport: (id: string, file: string) => Promise<void>;
    resetError: () => void;
}

const NAMESPACE = "services:reports";
const SEARCHABLE_FIELDS = ["fullName", "birthDay"];
const INITIAL_STATE = {
    error: null,
    loading: false,
    reports: null,
};

const serviceState = atom<State>({
    default: INITIAL_STATE,
    key: NAMESPACE,
});

/**
 * Fetch the reports and enrich from external database
 */
const getReports = async (): Promise<Report[]> => {
    try {
        const response: AxiosResponse<ReportsResponse> = await getClient().get(
            "/recording",
        );

        return await Promise.all(
            response.data.recordings.map(async (report: ReportData) => ({
                ...report,
            })),
        );
    } catch (error) {
        if (error?.response?.status === 403) {
            const response: AxiosResponse<OldReportsResponse> =
                await getClient().get("/patient");

            return await Promise.all(
                response.data.patients.map(async (report: ReportData) => ({
                    ...report,
                })),
            );
        } else {
            return [];
        }
    }
};

/**
 * Scan for recordings
 */
const scan = async () => {
    const state = getRecoil(serviceState);

    try {
        // SetRecoil
        setRecoil(serviceState, {
            ...state,
            loading: true,
        });

        // Enrich
        const reports = await getReports();

        if (reports.length) {
            setRecoil(serviceState, {
                ...state,
                loading: false,
                reports,
            });
        } else {
            setRecoil(serviceState, { ...state, loading: false });
        }
    } catch (error) {
        setRecoil(serviceState, { ...state, error, loading: false });
    }
};
const POLLER = Poller(scan);

/**
 * Get a field value according to a contract
 *
 * Merge the value to best effort (adding space)
 */
const getField = <T>(obj: T, key: string) => {
    if (!key.startsWith("join:")) {
        return get(obj, key);
    }

    return key
        .slice("join:".length)
        .split(";")
        .reduce((result, key) => `${result} ${get(obj, key, "")}`, "");
};

/**
 * Filter the users based on search input
 */
const filter = (reports: Report[] | null, query?: string | void) => {
    if (!reports || !query) {
        return reports;
    }

    return reports.filter((report: Report) => {
        const record = SEARCHABLE_FIELDS.map(key => {
            if (key === "birthDay") {
                return DateTime.fromSeconds(parseInt(report.birthDay)).toFormat(
                    "d/M/y",
                );
            }

            return get(report, key)?.toLowerCase();
        }).join(" ");

        if (record.indexOf(query?.toLowerCase()) >= 0) {
            return true;
        }

        return false;
    });
};

/**
 * Sort the users according to the desired values
 */
const sorted = (
    reports: Report[] | null,
    sort?: { order: "asc" | "desc"; field: string } | void,
) => {
    if (!reports || !sort) {
        return reports;
    }

    return reports.sort((first: Report, last: Report) => {
        const firstField = getField(first, sort.field);
        const lastField = getField(last, sort.field);

        if (firstField < lastField) {
            return sort.order === "asc" ? -1 : 1;
        }

        if (firstField > lastField) {
            return sort.order === "desc" ? -1 : 1;
        }

        return 0;
    });
};

/**
 * Download file when available
 */
const openReport = async (id: string, file: string): Promise<void> => {
    const state = getRecoil(serviceState);

    try {
        setRecoil(serviceState, {
            ...state,
            loading: true,
        });

        const response: AxiosResponse<{ signedUrl: string }> =
            await getClient().post(`/recording/${id}/signedurl`, {
                fileName: file,
                type: "report",
            });

        setRecoil(serviceState, {
            ...state,
            loading: false,
        });

        window.location.href = response.data.signedUrl;
    } catch (error) {
        if (error?.response?.status === 403) {
            const response: AxiosResponse<{ signedUrl: string }> =
                await getClient().post(`/patient/${id}/signedurl`, {
                    fileName: file,
                    type: "report",
                });

            setRecoil(serviceState, {
                ...state,
                loading: false,
            });

            window.location.href = response.data.signedUrl;
        } else {
            // eslint-disable-next-line no-console
            console.error(error);
            setRecoil(serviceState, {
                ...state,
                error,
                loading: false,
            });
        }
    }
};

const resetError = () => {
    const state = getRecoil(serviceState);

    setRecoil(serviceState, {
        ...state,
        error: null,
    });
};

let activeHooks = 0;
export const useReportsService = (
    query?: string | void,
    sort?: { order: "asc" | "desc"; field: string } | void,
): Hook => {
    const state = useRecoilValue(serviceState);

    useEffect(() => {
        activeHooks++;
        // Stop first to trigger a new polling loop immediatly
        POLLER.stop();
        POLLER.start();

        return () => {
            activeHooks--;

            if (activeHooks <= 0) {
                POLLER.stop();
            }
        };
    }, []);

    return {
        ...state,
        openReport,
        reports: sorted(filter(state.reports, query), sort),
        resetError,
    };
};
